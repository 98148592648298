<template>
  <section :class="`structure section ${getCurrentLangs.lang}`">
    <Lottie
      :options="defaultOptions"
      class="structure__media mob"
      @animCreated="handleAnimationMob"
      id="structure-animation-mob"
      @play="startPlay"
    />
    <div class="structure__top">
      <h2 class="structure__title section__title iosTitle type1">
        <span class="text-wrapper">
          <span class="letters">
            {{ `${$t('mainPage.structure.title[0]') + '&nbsp;'}` }}
          </span>
        </span>
        <br>
        <span class="text-wrapper second-part">
          <span class="letters">
            {{ $t('mainPage.structure.title[1]') }}
          </span>
        </span>
      </h2>
    </div>
    <div class="structure__content">
      <div class="structure__left grow">
        <h4 class="section__subtitle structure__subtitle">
          {{ $t('mainPage.structure.subtitle[0]') }}
          <br>
          {{ $t('mainPage.structure.subtitle[1]') }}
        </h4>
        <p class="section__description structure__description">{{ $t('mainPage.structure.description') }}</p>
        <Lottie
          :options="defaultOptions"
          class="structure__media desc"
          @animCreated="handleAnimation"
          @play="startPlay"
          id="structure-animation"
        />
      </div>
      <ul class="structure__right section__list grow">
        <li class="structure__item section__item">
          <h5 class="structure__item--title section__item--title">{{ $t('mainPage.structure.list.firstItem.title') }}</h5>
          <p class="structure__item--text section__item--text">
            {{ $t('mainPage.structure.list.firstItem.subtitle[0]') }}
            <br>
            {{ $t('mainPage.structure.list.firstItem.subtitle[1]') }}
          </p>
        </li>
        <li class="structure__item section__item">
          <h5 class="structure__item--title section__item--title">{{ $t('mainPage.structure.list.secondItem.title') }}</h5>
          <p class="structure__item--text section__item--text">{{ $t('mainPage.structure.list.secondItem.subtitle') }}</p>
        </li>
        <li class="structure__item section__item">
          <h5 class="structure__item--title section__item--title">{{ $t('mainPage.structure.list.thirdItem.title') }}</h5>
          <p class="structure__item--text section__item--text">{{ $t('mainPage.structure.list.thirdItem.subtitle') }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Lottie from 'vue-lottie';
import animationData from '@/assets/style/animation/structure.json';
import getElementVisible from '@/helper';

export default {
  name: 'structure',
  components: {
    Lottie,
  },
  data() {
    return {
      defaultOptions: {
        animationData,
        loop: false,
        autoplay: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVisibleStage',
      'getCurrentLangs'
    ]),
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    handleAnimationMob(anim2) {
      this.animMob = anim2;
    },
    play() {
      this.anim.play();
    },
    playMob() {
      this.animMob.play();
    },
    startPlay() {
      if (getElementVisible('structure-animation')) {
        this.play();
      }
      if (getElementVisible('structure-animation-mob')) {
        this.playMob();
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.startPlay);
  },
};
</script>

<style lang="scss" scoped>
.structure {
  display: flex;
  flex-direction: column;
  padding-left: 408px;
  padding-right: 440px;

  &__title {
    margin-left: 22%;

    .text-wrapper.second-part {
      margin-left: 21%;
    }
  }
  &__subtitle {
    margin-bottom: 28px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 35%;
    margin-top: 38px;
  }

  &__media {
    height: unset !important;
    &.mob {
      display: none;
    }
    &.desc {
      max-width: 600px;
    }
  }

  &__item {
    margin-bottom: 60px;
    &--text {
      font-size: 16px;
    }
  }

  &__description {
    // margin-bottom: 58px;
    max-width: 80%;
  }

  &__right {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1600px) {
  .structure {
    padding-left: 182px;
    padding-right: 258px;
    &__media {
      &.desc {
        max-width: 500px;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .structure {
    padding-left: 165px;
    padding-right: 200px;
    &__media {
      &.desc {
        max-width: 500px;
      }
    }
  }
}
@media screen and (max-width: 1370px) {
  .structure {
    padding-left: 165px;
    padding-right: 175px;
    &__media {
      &.desc {
        max-width: 500px;
      }
    }
  }
}
@media screen and (max-width: 960px) {
  .structure {
    padding-left: 44px;
    padding-right: 52px;

    &__subtitle {
      margin-bottom: 8px;
    }
    &__content {
      grid-template-columns: 1fr 42%;
    }
    &__item {
      margin-bottom: 48px;
    }
    &__description {
      margin-bottom: 80px;
    }
    &__media.desc {
      max-width: 380px;
      margin-left: 0 !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .structure {
    &__title {
      margin-left: 35%;
    }
    &__media.desc {
      max-width: 340px;
      margin-left: 0 !important;
    }
  }
}
@media screen and (max-width: 660px) {
  .structure {
    padding-left: 20px;
    padding-right: 20px;
    &__content {
      grid-template-columns: 1fr;
      margin-top: 16px;
    }
    &__media {
      max-width: 74%;
      margin: 0 auto 62px auto;
      &.desc {
        display: none;
        opacity: 0;
      }
      &.mob {
        display: block;
      }
    }
    &__title {
      text-align: left;
      max-width: 100%;
      margin-left: 0;
      .text-wrapper {
        padding-bottom: 0px;
        &.second-part {
          margin-left: 0%;
          padding-bottom: 4px;
        }
      }
    }
    &__description {
      margin-bottom: 48px;
      max-width: 100%;
    }
    &__item {
      &--text > br:first-of-type {
        display: none;
      }
    }
  }
}
</style>
